/**
 *  Header component
 *
 *  This statefull component shows how to use react hooks and basic property types with gatsby
 */

import { Link } from "gatsby"
import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"
import { EmailIcon, PhoneIcon, WhatsappIcon } from "../shared/icons"
import Logo from "../shared/logo"
import { AppContext } from "../layout"

type FooterProps = {
  location?: string
}

const Footer = ({ location }: FooterProps): JSX.Element => {
  const data = useStaticQuery<GatsbyTypes.GetContactDataQuery>(graphql`
    query GetContactData {
      site {
        siteMetadata {
          telefon
          whatsapp
          email
          STRASSE1
          STRASSE2
          PLZ1
          PLZ2
          ORT1
          ORT2
          OPENING1A
          OPENING1B
          OPENING2A
          OPENING2B
        }
      }
    }
  `)

  if (!data.site?.siteMetadata)
    throw new Error("SiteMetaData.title possibly undefined")

  const contactData = data.site?.siteMetadata

  // Make Numbers only
  const makeDigits = (string: string): string => {
    const res = string.replace(/\D/g, "")
    return res
  }

  const { toggleMenu } = React.useContext(AppContext)

  console.log(location)

  return (
    <footer
      id="footer"
      className="relative overflow-hidden pt-12 md:pt-16 xl:pt-32 xxxl:pt-48"
    >
      {/* Background */}
      <div
        className={
          location == "/dein-weg-zum-fuehrerschein"
            ? "absolute bg-secondary top-0 w-full h-full -z-10 scale-110 translate-y-12 "
            : "absolute bg-secondary top-0 w-full h-full -z-10 -rotate-1 scale-110 translate-y-12 md:translate-y-16 xl:translate-y-32 xxxl:translate-y-48"
        }
      ></div>
      {/* Footer Content */}
      <div className="container-fluid text-white pb-12  pt-6 md:pt-8 xl:pt-16 xxxl:pt-24">
        {/* Reihe Titel */}
        <div className="w-full h-12 z-10 relative lg:w-2/3 sm:h-20 xl:h-32 xxl:h-40">
          <h2 className="text-white">
            Melde Dich jetzt für deinen Führerschein an!
          </h2>
        </div>
        {/* Reihe 2 Bild Contact */}
        <div className="w-full sm:flex sm:flex-nowrap sm:space-x-12 xll:space-x-24 justify-center">
          {/* Spalte  Bild */}
          <div className=" flex justify-end mb-12 sm:mb-0 sm:w-1/2 lg:w-full">
            {/* Bild */}
            <div className="pl-12 sm:pl-0 lg:w-5/6 xxl:w-3/4  h-full ">
              <StaticImage
                src="../../images/jetzt-anmelden.jpg"
                alt="Melde dich jetzt an"
                className="h-full object-cover"
              />
            </div>
          </div>
          {/* Spalte Kontakt-Links */}
          <div className="sm:flex sm:justify-center sm:mt-12  sm:items-end md:justify-start md:mt-24 sm:w-1/2">
            <ul className="contact-link-list ">
              <li>
                <button
                  onClick={() => toggleMenu(true)}
                  // eslint-disable-next-line max-len
                  className="bg-white border-2 border-white text-sm xll:text-lg px-6 py-3 text-bold rounded-full text-secondary transition-all hover:text-white hover:bg-secondary"
                >
                  Jetzt anmelden
                </button>
              </li>
              <li>
                Hast du Fragen? Rufe uns an oder schicke
                <br /> uns eine WhatsApp- Nachricht!
              </li>
              <li>
                <EmailIcon className="contact-icon" isWhite />
                <a href={`mailto:${contactData.email}`}>E-Mail</a>
              </li>
              <li>
                <PhoneIcon className="contact-icon" isWhite />
                <a
                  href={`tel:${makeDigits(
                    JSON.stringify(contactData.telefon)
                  )}`}
                >
                  {contactData.telefon}
                </a>
              </li>
              <li>
                <WhatsappIcon className="contact-icon" isWhite />
                <a href={`https://wa.me/${contactData.whatsapp}`}>WhatsApp</a>
              </li>
            </ul>
          </div>
        </div>
        {/* Reihe 3 Links */}
        <div className="mt-16 md:mt-24 w-full grid grid-cols-1 gap-y-12 md:grid-cols-2   lg:grid-cols-5 lg:gap-y-0 lg:space-y-0 lg:gap-x-20 ">
          {/* Spale 1 Logo */}
          <div className="md:order-1">
            <ul className="flex flex-col space-y-2 md:space-y-4">
              <li>
                <Logo isWhite className="w-36 " />
              </li>
            </ul>
          </div>
          {/* Spalte 2 Ort 1 */}
          <div className=" md:order-2">
            <ul className="flex flex-col space-y-1 md:space-y-1 ">
              <li className="text-ultrabold text-xl">Farschule Tarup</li>
              <li>{contactData.OPENING1A}</li>
              <li>{contactData.OPENING1B}</li>
              <li>{contactData.STRASSE1}</li>
              <li>
                {contactData.PLZ1} {contactData.ORT1}
              </li>
            </ul>
          </div>
          {/* Spalte 3 Ort 2 */}
          <div className="md:order-3">
            <ul className="flex flex-col space-y-1 md:space-y-1 ">
              <li className="text-ultrabold text-xl">Farschule Satrup</li>
              <li>{contactData.OPENING2A}</li>
              <li>{contactData.OPENING2B}</li>
              <li>{contactData.STRASSE2}</li>
              <li>
                {contactData.PLZ2} {contactData.ORT2}
              </li>
            </ul>
          </div>

          {/* Spalte 4 Links */}
          <div className=" md:order-4">
            <ul className="flex flex-col space-y-2 md:space-y-2 ">
              <li>
                <Link to="/">Startseite</Link>
              </li>
              <li>
                <Link to="/fuehrerscheinklassen">Führerscheinklassen</Link>
              </li>
              <li>
                <Link to="/dein-weg-zum-fuehrerschein">
                  Dein Weg zum Führerschein
                </Link>
              </li>
              <li>
                <Link to="/theorieunterricht">
                  Dein Fahrplan zum Theorieunterricht
                </Link>
              </li>
            </ul>
          </div>
          {/* Spale 1 Logo */}
          <div className="w-full md:order-5">
            <ul className="flex flex-col space-y-2 md:space-y-2">
              <li>
                <Link to="/impressum">Impressum</Link>
              </li>
              <li>
                <Link to="/datenschutz">Datenschutz</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
